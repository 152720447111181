import React from "react"
import _ from "lodash"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter"
import Text from "../../components/text/text"
import { CopyToClipboard } from "react-copy-to-clipboard"
import Moment from "react-moment"

const format = require("string-format")

class ImplementationTemplate extends React.Component {
  render() {
    // console.log(this.props)
    const {
      algorithm,
      language,
      code,
      otherLanguages,
      otherAlgorithms,
    } = this.props.pageContext
    const lang = algorithm.frontmatter.lang
    const general_strings = require("./../../../content/" + lang + "/strings.json")
    const implementation_strings = require("./../../../content/" + lang + "/implementations/strings.json")
    const title = format(implementation_strings.title, algorithm.frontmatter.name, language.frontmatter.name)
    return (
      <Layout lang={lang}>
        <SEO
          title={title}
          description={algorithm.excerpt + " | " + language.excerpt}
          lang={algorithm.frontmatter.lang}
        />

        <article>
          <header>
            <h1>
              {title}
            </h1>
            <p>
              {general_strings.posted + ": "}
              <i><Moment format="YYYY-MM-DD HH:mm" date={code.created_at}/></i>
              {", " + general_strings.last_updated + ": "}
              <i><Moment format="YYYY-MM-DD HH:mm" date={code.updated_at}/></i>
            </p>
          </header>
          <section className="row">
            <div className="col-lg-12">
              <CopyToClipboard text={code.content} onCopy={x => console.log(x)}>
                <button className={"btn btn-primary"}>{general_strings.copy_to_clipboad}</button>
              </CopyToClipboard>
              <SyntaxHighlighter language={language.frontmatter.slug}>
                {code.content}
              </SyntaxHighlighter>
            </div>
          </section>
          <section className={"row"}>
            <div className="col-lg-12">
              <h2>{implementation_strings.about_implementation}</h2>
            </div>
            <div className="col-lg-6">
              <Text html={algorithm.html} title={algorithm.frontmatter.title}/>
              <div className={"card mb-4"}>
                <div className={"card-header"}>
                  {format(implementation_strings.other_languages, algorithm.frontmatter.title)}
                </div>
                <div className={"card-body"}>
                  <ul>
                    {_.map(otherLanguages, other => {
                        const path = "/" + (lang !== "en" ? lang + "/" : "") + other.algorithm.frontmatter.slug + "/" + other.language.frontmatter.slug
                        return <li key={path}>
                          <a href={path}>
                            {format(implementation_strings.title, other.algorithm.frontmatter.name, other.language.frontmatter.name)}
                          </a>
                        </li>
                      },
                    )}
                  </ul>
                  <a href={"/" + (lang !== "en" ? lang + "/" : "") + algorithm.frontmatter.slug}
                     className="btn btn-light btn-md center">
                    {format(implementation_strings.all_languages, algorithm.frontmatter.name)}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Text html={language.html} title={language.frontmatter.title}/>
              <div className={"card mb-4"}>
                <div className={"card-header"}>
                  {format(implementation_strings.other_algorithms, language.frontmatter.name)}
                </div>
                <div className={"card-body"}>
                  <ul>
                    {_.map(otherAlgorithms, other => {
                        const path = "/" + (lang !== "en" ? lang + "/" : "") + other.algorithm.frontmatter.slug + "/" + other.language.frontmatter.slug
                        return <li key={path}>
                          <a href={path}>
                            {format(implementation_strings.title, other.algorithm.frontmatter.name, other.language.frontmatter.name)}
                          </a>
                        </li>
                      },
                    )}
                  </ul>
                  <a href={"/" + (lang !== "en" ? lang + "/" : "") + language.frontmatter.slug}
                     className="btn btn-light btn-md center">
                    {format(implementation_strings.all_algorithms, language.frontmatter.name)}
                  </a>
                </div>
              </div>
            </div>
          </section>
        </article>
      </Layout>
    )
  }
}

export default ImplementationTemplate
